import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AMPLIFY_CONFIG from '../config/AmplifyConfig';
import PrivateRoute from './PrivateRoute';
import NotFound from '../components/global/layout/NotFound';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import NoSidebarLayout from '../components/dashboard/NoSidebarLayout';
import SignUp from 'components/auth/SignUp';
import Login from 'components/auth/Login';
import Dashboard from 'components/dashboard/panel';
import Profile from 'components/profile';
import BelongScore from 'components/belong-score';
import BelongScoreRoutes from './BelongScoreRoutes';
import Phone from 'components/auth/phone-verification';

import ForgotPassword from 'components/auth/forgotPassword/ForgotPassword';
import PrivacyPolicy from 'components/home/footer-Links/PrivacyPolicy';
import TermsConditions from 'components/home/footer-Links/TermsConditions';
import AboutUs from 'components/home/footer-Links/AboutUs';
import ContactUs from 'components/home/footer-Links/ContactUs';
import CheckLink from 'components/auth/CheckLink';
import JKLU from './JKLU';
import Leaderboard from 'components/dashboard/leaderboard';
import Assignment from 'components/assignment';

const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path='jklu' element={<JKLU />} />
        <Route path='/signup'>
          <Route index element={<CheckLink />} />
          <Route path=':slug' element={<SignUp />} />
        </Route>
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='/' element={<NoSidebarLayout />}>
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-&-conditions' element={<TermsConditions />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='contact' element={<ContactUs />} />
        </Route>
        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path='belong-score' element={<BelongScore />} />
            <Route path='leaderboard' element={<Leaderboard />} />
            <Route path='assignment' element={<Assignment />} />
          </Route>
          <Route path='/' element={<NoSidebarLayout />}>
            <Route path='profile' element={<Profile />} />
            {BelongScoreRoutes}
            <Route path='phone-verify' element={<Phone />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
