import { supabase } from 'config/supabaseConfig';
import React, { useState } from 'react';
import UploadFile from './UploadFile';
import { useGetUserQuery } from 'components/profile/userSlice';

const BasicCv = ({ userDetails, setRefetch }: any) => {
  const [fileUrl, setFileUrl] = useState('');
  const { data: userData } = useGetUserQuery();

  const user_id = userData?._id;

  async function submitFn() {
    const { error } = await supabase
      .from('fiib_users')
      .update({ basic_cv: fileUrl })
      .match({ user_id });
    setRefetch((pre: any) => !pre);
    console.log(error);
  }

  return (
    <div className='p-4 bg-white shadow-md rounded-lg border border-gray-200'>
      {userDetails.basic_cv ? (
        <div className='space-y-4'>
          <a
            className='text-primary font-semibold underline hover:text-primary-dark'
            href={userDetails.basic_cv}
            target='_blank'
            rel='noreferrer'
          >
            View Resume
          </a>
          {userDetails.basic_cv_feedback && (
            <div className='p-3 bg-gray-100 rounded-lg border border-gray-300'>
              <h3 className='font-semibold text-gray-700'>Feedback</h3>
              <p className='text-gray-600 mt-1'>
                {userDetails.basic_cv_feedback}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='space-y-4 '>
          <div className='max-w-3xl p-6 bg-white  rounded-lg'>
            <h1 className='text-2xl font-bold '>CV Submission Guidelines</h1>

            <section className='mt-4'>
              <h2 className='text-lg font-semibold text-gray-700'>
                Required Sections:
              </h2>
              <ul className='list-disc list-inside text-gray-600 mt-2'>
                <li>Header</li>
                <li>Objective/Professional Summary</li>
                <li>Education</li>
                <li>Projects and/or Work Experience</li>
                <li>Skills</li>
                <li>Certifications and Achievements</li>
              </ul>
            </section>

            <section className='mt-4'>
              <h2 className='text-lg font-semibold text-gray-700'>
                Submission Requirements:
              </h2>
              <p className='text-gray-600 mt-2'>
                Before submission, ensure that you utilize AI tools to refine
                the language and enhance clarity. Your CV should follow a
                professional format, maintaining consistency in structure,
                readability, and relevance to your career goals.
              </p>
            </section>

            <section className='mt-4'>
              <h2 className='text-lg font-semibold text-gray-700'>
                Submission Deadline:
              </h2>
              <p className='text-gray-600'>03rd Feb 2025</p>
            </section>

            <section className='mt-4'>
              <h2 className='text-lg font-semibold text-gray-700'>
                Evaluation & Feedback:
              </h2>
              <p className='text-gray-600 mt-2'>
                Your CV will be assessed based on clarity, consistency,
                relevance, accuracy, grammar, structure, and overall
                professional appearance.
              </p>
              <p className='text-gray-600 mt-2'>
                The trainer will provide detailed feedback highlighting
                strengths and areas for improvement. Feedback will focus on how
                well your CV communicates your skills and experiences, whether
                it is structured logically, free from errors, and visually
                polished.
              </p>
            </section>
          </div>
          <UploadFile setFieldValue={setFileUrl} />
          {fileUrl && (
            <button
              onClick={submitFn}
              className='mt-3 px-4 py-2 bg-primary text-white font-medium rounded-lg hover:bg-primary-dark transition'
            >
              Submit Resume
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BasicCv;
