import React, { useEffect } from 'react';
import QuizScoreChart from '../../QuizScoreChart';
import { Link } from 'react-router-dom';
import { useGetUserQuery } from 'components/profile/userSlice';
import { supabase } from 'config/supabaseConfig';

type scoreProps = {
  data: any;
};

const ShowFinalScore = (props: scoreProps) => {
  function getCategoryPercentage(cat: any) {
    const currentCategory = props.data.finalScore.filter((category: any) => {
      return category.category === cat;
    })[0];
    const catPercentage = Math.ceil(
      (currentCategory.obtained * 100) / currentCategory.max
    );
    return catPercentage;
  }

  const percentage = Math.ceil(props.data.percentage);

  const color = percentage > 25 ? '#9BBCEC' : '#EC9B9B';

  const { data: userData } = useGetUserQuery();

  const user_id = userData?._id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data: userData } = await supabase
        .from('fiib_users')
        .select('*')
        .match({ user_id });
      if (userData && !userData[0]) {
        const { error } = await supabase
          .from('fiib_users')
          .insert({ user_id, quiz_score: percentage });

        console.log(error);
      }
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id, percentage]);

  return (
    <div className='m-1 sm:m-4'>
      <div className='hidden text-gray-500 font-semibold m-2'>
        Congratualtions! You can start Applying to Oppotunitues
      </div>
      <div className='bg-gray-50 p-4 mt-8'>
        <div className='sm:flex text-gray-500 font-semibold'>
          <div>
            <div className='mb-6 text-center '>Your Score Card</div>
            <div className='flex justify-center'>
              <QuizScoreChart percentage={percentage} color={color} />
            </div>
          </div>
          <div className='mt-12 sm:ml-16'>
            <div className='mb-4'>
              The Assessment assesses on the 4 essential workplace readiness
              skills :
              <span className='text-primary'>
                Communication, Analytics, Creativity & Self- Awareness
              </span>
            </div>
            <ul className='list-disc ml-8'>
              <li>
                Business Communication -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('COMMUNICATION')} %
                </span>
              </li>
              <li>
                Reasoning & Creativity -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('CREATIVITY')} %
                </span>{' '}
              </li>
              <li>
                Observation & Analytics -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('ANALYTICAL_SKILLS')} %
                </span>{' '}
              </li>
              <li>
                Self Awareness -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('SELF_AWARENESS')} %
                </span>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='flex justify-center mt-4 sm:mt-12'>
        <Link
          to='/'
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
        >
          End Session
        </Link>
      </div>
    </div>
  );
};

export default ShowFinalScore;
