import { supabase } from 'config/supabaseConfig';
import React, { useCallback } from 'react';

const UploadFile = ({ setFieldValue }: any) => {
  function generateRandomString(length: any) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }
  const uploadFileToDB = useCallback(
    async (file: any) => {
      const fileName = `${generateRandomString(3)}${file.name}`;
      const { error } = await supabase.storage
        .from('offline-bootcamp')
        .upload('fiib/' + fileName, file);
      if (error) {
        console.log('Error uploading image:', error);
      }
      const publicUrl = supabase.storage
        .from('offline-bootcamp')
        .getPublicUrl('fiib/' + fileName);
      setFieldValue(publicUrl?.data?.publicUrl || '');
    },
    [setFieldValue]
  );
  const handleImageChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      uploadFileToDB(file);
    }
  };
  return (
    <div>
      <input type='file' onChange={handleImageChange} />
    </div>
  );
};

export default UploadFile;
