import React, { useEffect, useState } from 'react';
import BasicCv from './BasicCv';
import { useGetUserQuery } from 'components/profile/userSlice';
import { supabase } from 'config/supabaseConfig';
import Loading from 'components/global/layout/Loading';
import VideoCv from './VideoCv';
import Linkedin from './Linkedin';
import FinalCv from './FinalCv';

const Index = () => {
  const [currenttab, setCurrentTab] = useState('BASIC_CV');
  const [refetch, setRefetch] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isLoading, setLoading] = useState(true);

  const { data: userData } = useGetUserQuery();

  const user_id = userData?._id;

  useEffect(() => {
    const setDataFn = async () => {
      const { data: userData } = await supabase
        .from('fiib_users')
        .select('*')
        .match({ user_id });
      if (userData && userData[0]) {
        setUserDetails(userData[0]);
      }

      setLoading(false);
    };
    if (user_id) {
      setDataFn();
    }
  }, [user_id, refetch]);

  const allTabs = [
    { id: 'BASIC_CV', name: 'CV Basics', isDisabled: false },
    { id: 'VIDEO_CV', name: 'Video CVs', isDisabled: true },
    { id: 'LINKEDIN', name: 'LinkedIn Optimization', isDisabled: true },
    { id: 'FINAL_CV', name: 'Enhancing CVs', isDisabled: true },
  ];

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : userDetails ? (
        <>
          <div className='p-6 '>
            <div className='flex gap-4 border-b-2 border-gray-300 pb-2'>
              {allTabs.map((el) => {
                const isActive = currenttab === el.id;
                return (
                  <div
                    key={el.id}
                    onClick={() => {
                      if (!el.isDisabled) setCurrentTab(el.id);
                    }}
                    className={`
                px-4 py-2 cursor-pointer rounded-t-lg text-sm font-medium
                transition-all duration-200
                ${
                  el.isDisabled
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : ''
                }
                ${
                  isActive && !el.isDisabled
                    ? 'bg-primary text-white'
                    : !el.isDisabled
                    ? 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    : ''
                }
              `}
                  >
                    {el.name}
                  </div>
                );
              })}
            </div>
            <div className='mt-4 p-4 bg-white shadow rounded-lg'>
              {currenttab === 'BASIC_CV' && (
                <BasicCv userDetails={userDetails} setRefetch={setRefetch} />
              )}
              {currenttab === 'VIDEO_CV' && (
                <VideoCv userDetails={userDetails} setRefetch={setRefetch} />
              )}
              {currenttab === 'LINKEDIN' && (
                <Linkedin userDetails={userDetails} setRefetch={setRefetch} />
              )}
              {currenttab === 'FINAL_CV' && (
                <FinalCv userDetails={userDetails} setRefetch={setRefetch} />
              )}
            </div>
          </div>
        </>
      ) : (
        <div>Please complete Aptitude Quiz</div>
      )}
    </>
  );
};

export default Index;
