import { supabase } from 'config/supabaseConfig';
import React, { useState } from 'react';
import UploadFile from './UploadFile';
import { useGetUserQuery } from 'components/profile/userSlice';

const FinalCv = ({ userDetails, setRefetch }: any) => {
  const [fileUrl, setFileUrl] = useState('');
  const { data: userData } = useGetUserQuery();

  const user_id = userData?._id;

  async function submitFn() {
    const { error } = await supabase
      .from('fiib_users')
      .update({ final_cv: fileUrl })
      .match({ user_id });
    setRefetch((pre: any) => !pre);
    console.log(error);
  }
  return (
    <div className='p-4 bg-white shadow-md rounded-lg border border-gray-200'>
      {userDetails.final_cv ? (
        <div className='space-y-4'>
          <a
            className='text-primary font-semibold underline hover:text-primary-dark'
            href={userDetails.final_cv}
            target='_blank'
            rel='noreferrer'
          >
            View Resume
          </a>
          {userDetails.final_cv_feedback && (
            <div className='p-3 bg-gray-100 rounded-lg border border-gray-300'>
              <h3 className='font-semibold text-gray-700'>Feedback</h3>
              <p className='text-gray-600 mt-1'>
                {userDetails.final_cv_feedback}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='space-y-4 text-center'>
          <UploadFile setFieldValue={setFileUrl} />
          {fileUrl && (
            <button
              onClick={submitFn}
              className='mt-3 px-4 py-2 bg-primary text-white font-medium rounded-lg hover:bg-primary-dark transition'
            >
              Submit Resume
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default FinalCv;
