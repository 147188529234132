import { supabase } from 'config/supabaseConfig';
import React, { useState } from 'react';
import { useGetUserQuery } from 'components/profile/userSlice';

const Linkedin = ({ userDetails, setRefetch }: any) => {
  const [fileUrl, setFileUrl] = useState('');
  const { data: userData } = useGetUserQuery();

  const user_id = userData?._id;

  async function submitFn() {
    const { error } = await supabase
      .from('fiib_users')
      .update({ linkedin: fileUrl })
      .match({ user_id });
    setRefetch((pre: any) => !pre);
    console.log(error);
  }

  return (
    <div className='p-6 bg-white shadow-lg rounded-lg border border-gray-200'>
      {userDetails.linkedin ? (
        <div className='space-y-4'>
          <a
            className='text-primary font-semibold underline hover:text-primary-dark transition'
            href={userDetails.linkedin}
            target='_blank'
            rel='noreferrer'
          >
            View Linkedin Profile
          </a>
          {userDetails.linkedin_feedback && (
            <div className='p-4 bg-gray-100 rounded-lg border border-gray-300'>
              <h3 className='font-semibold text-gray-700'>Feedback</h3>
              <p className='text-gray-600 mt-2'>
                {userDetails.linkedin_feedback}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='space-y-4 text-center'>
          <label className='block text-gray-700 font-medium'>
            Enter Linkedin URL
          </label>
          <input
            type='text'
            value={fileUrl}
            onChange={(e) => setFileUrl(e.target.value)}
            className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary'
            placeholder='Paste your link here...'
          />
          {fileUrl && (
            <button
              onClick={submitFn}
              className='mt-3 px-6 py-2 bg-primary text-white font-medium rounded-lg hover:bg-primary-dark transition'
            >
              Submit
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Linkedin;
